"use strict";

$(function() {
	// リサイズしたらリロードする（スマホ以外）
	// const $winWidth = $(window).width();
	// $(window).on('resize', function() {
	// 	const $winWidthResize = $(window).width();
	// 	if($winWidth !== $winWidthResize) {
	// 		location.href = location.href;
	// 	}
	// });

	// ヘッダー ドロップダウンメニュー
	$('.header__dropdown').on('click', function() {
		$('.header__dropdown__list').toggleClass('is-show');
		$('.header__dropdown__arrow').toggleClass('is-rotated');
	});

	// フッター ドロップダウンメニュー
	$('.footer__sub-menu__header').on('click', function() {
		$(this).next('.footer__sub-menu__list').toggleClass('is-show');
		$(this).find('.footer__sub-menu__arrow').toggleClass('is-rotated');
	});
	$('.footer__sub-menu__item').on('click', function(){
		$(this).parent('.footer__sub-menu__list').removeClass('is-show');
	});

	// ヘッダー&フッター sp用処理
	const winW = $(window).width();
	if ( winW <= 767 ) {
		$('.footer__column__header').on('click', function() {
			$(this).next('.footer__column__list').toggleClass('is-show');
			$(this).find('.footer__column__arrow').toggleClass('is-rotated');
		});
		$('.header__dropdown__arrow').html('<img src="./images/common/icon_arrow-down-white.svg" alt="arrow">');
	} else {
		$('.header__dropdown__arrow').html('<img src="./images/common/icon_arrow-triangle.svg" alt="arrow">');
	}

	// page__for-experts用ヘッダー
	if ($('body').hasClass('page__for-experts')) {
		$('.header__logo__link').html('<img src="./images/common/logo_ja-white-text.png" alt="logo white-text">');
	}
	if ($('body').hasClass('page__for-experts') && winW <= 767) {
		$('.header__dropdown__arrow').html('<img src="./images/common/icon_arrow-down-white.svg" alt="arrow">');
	} else if ($('body').hasClass('page__for-experts')) {
		$('.header__dropdown__arrow').html('<img src="./images/common/icon_arrow-triangle-white.svg" alt="arrow">');
	}

	// ハンバーガー
	$('.header__toggle').on('click', function() {
		$('body').toggleClass('is-open');
		$('html').toggleClass('scroll-prevent');
	});
	$('.header__nav a').on('click', function() {
		$('body').removeClass('is-open');
		$('html').removeClass('scroll-prevent');
	});

	// フォーム
	$('.home .hero__form__fields, .home .hero__form__placeholder').on('click', function() {
		$('.hero__form__placeholder').hide();
		if (winW <= 767) {
			$('body').addClass('is-form-open');
			$('html').addClass('scroll-prevent');
			$('body, html').animate({ scrollTop: 0 }, 500);
		} 
	});
	$('.hero__form__header__close-button, .hero__form__overlay').on('click', function() {
		$('body').removeClass('is-form-open');
		$('html').removeClass('scroll-prevent');
		$('.hero__form__placeholder').show();
	});

	// ityped
	if ($('body').hasClass('home') || $('body').hasClass('page__price')) {
		ityped.init(document.querySelector("#ityped"), {
			strings: [
				'a lawyer...', 
				'a doctor...',
				'a veterinarian...',
				'a tech specialist...',
				'a mechanic...',
				'a contractor...',
				'an appraiser...',
				'an Expert...',
			], 
			typeSpeed: 80,
			startDelay: 1000,
			backSpeed: 100,
			backDelay: 2500,
			loop: true,
			showCursor: true,
			cursorChar: "|",
		});
	}

	// how-it-works__pc 画像&背景色 切り替え
	const steps = $('.how-it-works__pc__step')
	steps.on('mouseover', function() {
		$('.is-active').removeClass('is-active');
		$(this).addClass('is-active');
		const index = steps.index(this);
		$('.how-it-works__pc__image__item').removeClass('is-show').eq(index).addClass('is-show');
	});

	// how-it-works__sp アコーディオン
	$('.how-it-works__sp__step__text-area').on('click', function() {
		$(this).next('.how-it-works__sp__step__image').slideToggle(500);
		$(this).find('.how-it-works__sp__step__arrow').toggleClass('is-rotated');
		$('.how-it-works__sp__step__text-area').not($(this)).next('.how-it-works__sp__step__image').slideUp(500);
	});

	 
	if ( winW <= 767 ) {
		// usage-flow organize アコーディオン
		$('.organize__item:first-of-type .organize__item__header').addClass('is-show');
		$('.organize__item:first-of-type .organize__item__body').show();
		$('.organize__item__header').on('click', function() {
			$(this).toggleClass('is-show');
			$(this).next('.organize__item__body').slideToggle(500);
		});

		// service sp アコーディオン
		$('.page__section__header').on('click', function() {
			$(this).find('.service__title').toggleClass('is-show');
			$(this).next('.page__section__body').slideToggle(500);
		});

		// service sp スムーススクロール
		$('a[href^="#"]').on('click', function() {
			const href = $(this).attr("href");
			const target =$(href === "#" || href === "" ? 'html' : href);
			const position = target.offset().top;
			$("html, body").animate({scrollTop: position}, 600);
		});
	}
});

// スライドショー
// home category
const swiper_category = new Swiper('.category__slide', {
	pagination: {
		el: '.swiper-pagination--category',
		type: 'bullets',
		clickable: true,
	},
	speed: 500,
	slidesPerView: 'auto',
	spaceBetween: 32,
});

// home meet-the-experts 
const swiper_MeetTheExperts = new Swiper('.meet-the-experts__slide__container', {
	loop: true,
	speed: 500,
  autoplay: {
    delay: 5000,
    disableOnInteraction: false
  },
	navigation: {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev'
	},
	pagination: {
		el: '.swiper-pagination--meet-the-experts',
		type: 'bullets',
		clickable: true
	},
	slidesPerView: 2.3,
	breakpoints: {
		768: {
			slidesPerView: 4.5,
			spaceBetween: 10,
		}
	},
});

// home testimonials
const swiper_testimonials = new Swiper('.testimonials__slide__container', {
	loop: true,
	speed: 500,
  autoplay: {
    delay: 5000,
    disableOnInteraction: false
  },
	pagination: {
		el: '.swiper-pagination--testimonials',
		type: 'bullets',
		clickable: true
	},
	slidesPerView: 2.3,
});

// how-it-works main-step
const swiper_mainStep = new Swiper('.main-step__slide', {
	loop: true,
	speed: 2000,
  autoplay: {
    delay: 4000,
    disableOnInteraction: false
  },
	pagination: {
		el: '.swiper-pagination--main-step',
		type: 'bullets',
		clickable: true
	},
});

// for-experts hero
const swiper5 = new Swiper('.hero__slide', {
  autoplay: {
    delay: 5000,
    disableOnInteraction: false
  },
	speed: 2000,
	effect: 'fade',
	fadeEffect: {
    crossFade: true
  },
});

// for-experts saying-about
const swiper_sayingAbout = new Swiper('.saying-about__slide', {
	loop: true,
  autoplay: {
    delay: 5000,
    disableOnInteraction: false
  },
	pagination: {
		el: '.swiper-pagination--saying-about',
		type: 'bullets',
		clickable: true
	},
});

// usage-flow decide
const swiper_usageFlowDecide = new Swiper('.decide__content__slide', {
	speed: 500,
  autoplay: {
    delay: 5000,
    disableOnInteraction: false
  },
	pagination: {
		el: '.swiper-pagination--decide',
		type: 'bullets',
		clickable: true
	},
	spaceBetween: 20,
});

